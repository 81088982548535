export default blockName => ({
  props: {
    editableCard: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    editMode() {
      return this.editableCard === blockName;
    },
  },
  methods: {
    saveChanges() {
      this.$emit('onUpdate', this.model);
    },
    checkEditBlock() {
      if (this.$isLimitedAccess()) {
        return this.$showLimitAccessError();
      }
      this.$emit('onChangeEditMode', !this.editMode ? blockName : null);
      this.initData();
    },
  },
});
