<template>
  <div :class="{ 'disabled': disabled }">
    <vue-dropzone
      id="id"
      ref="vueDropzone"
      class="cp-uploader model"
      :class="[documentError ? 'document-error': '', showDefaultMessage ? 'show-message' : '', classStyleOverride]"
      :options="dropZoneOptions"
      @vdropzone-success="fileUploaded"
      @vdropzone-file-added="onFileAdded"
      @vdropzone-files-added="uploadStart"
      @vdropzone-file-added-manually="uploadStart"
      @vdropzone-max-files-reached="onMaxFilesReached"
      @vdropzone-sending="onSending"
      @vdropzone-error="onError"
    />
    <div>
      <span
        v-if="documentError"
        class="invalid-feedback d-block"
      > {{ $t('component.cpFileUploader.error') }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

const getFileAPIBase = () => '/api/v2/';

export default {
  name: 'CpFileUploader',
  props: {
    classStyleOverride: {
      type: String,
      default: '',
    },
    fileUploadUrl: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    maxFileSize: {
      type: Number,
      default: 10,
    },
    timeout: {
      type: Number,
      default: 1800000, // 30m
    },
    extension: {
      type: String,
      default: '',
    },
    showDefaultMessage: {
      type: Boolean,
      default: false,
    },
    defaultUrl: {
      type: String,
      default: '',
    },
    fileRules: {
      type: Function,
      default: () => null,
    },
    dictDefaultMessage: {
      type: String,
      default: 'Drop files here or click to upload',
    },
    documentError: {
      type: Boolean,
      default: false,
    },
    isAwsUploader: {
      type: Boolean,
      default: true,
    },
    useSecure: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preSignedUrl: '#',
      fileKey: '',
      fields: {},
    };
  },
  computed: {
    dropZoneOptions() {
      const { fileRules } = this;
      const placeholderUrl = this.defaultUrl || this.placeholder || this.getImagePath('upload.png', 'theme');
      return {
        url: this.isAwsUploader ? this.preSignedUrl : this.getFileUploadUrl(),
        maxFilesize: this.maxFileSize,
        timeout: this.timeout,
        dictDefaultMessage: this.dictDefaultMessage || this.placeholder || 'Drop files here or click to upload',
        autoProcessQueue: true,
        acceptedFiles: this.extension || '.png, .jpeg, .jpg, .pdf',
        thumbnailMethod: 'contain',
        maxFiles: 1,
        accept(mainFile, done) {
          this.getRejectedFiles().forEach(file => (file.accepted !== undefined) && this.removeFile(file));
          this.getAcceptedFiles().forEach((file) => {
            this.removeFile(file);
          });

          done(fileRules(mainFile));
        },
        init() {
          this.removeAllFiles();
          if (placeholderUrl) {
            const mockFile = {};
            this.emit('addedfile', mockFile);
            this.emit('complete', mockFile);
            this.emit('thumbnail', mockFile, placeholderUrl.toString());
            this.on('addedfile', () => {
              this.emit('removedfile', mockFile);
            });
          }
        },
      };
    },
  },
  mounted() {
    this.getPresignedUrl();
  },
  methods: {
    ...mapActions('global', ['getUploadLink']),
    getImagePath(path, type) {
      path = path || 'logo.png';
      const fullPath = type ? `${type}/${path}` : path;
      if (/^http:|^https:|^\//i.test(path)) {
        return path;
      }
      try {
        // eslint-disable-next-line import/no-dynamic-require,global-require
        return require(`~/assets/img/${fullPath}`);
      } catch (e) {
        console.error('failed fetching: ', fullPath);
        return '';
      }
    },
    onFileAdded() {
      this.keepLastUploadedFile(this.$refs.vueDropzone.getRejectedFiles());
    },
    keepLastUploadedFile(files) {
      const filesToRemove = (files || []).slice(0, -1);
      if (filesToRemove.length) filesToRemove.forEach(file => this.$refs.vueDropzone.removeFile(file));
    },
    uploadStart() {
      this.$emit('fileLoading');
    },
    onMaxFilesReached(files) {
      this.keepLastUploadedFile(files);
    },
    onError(file) {
      this.$emit('onChange', {
        file,
        fileUrl: null,
        fileKey: null,
      });
    },
    fileUploaded(file, response = {}) {
      this.$emit('onChange', {
        file,
        fileUrl: this.isAwsUploader ? this.preSignedUrl : response.url,
        fileKey: this.isAwsUploader ? this.fileKey : response.key,
      });
    },
    onSending(file, req, formData) {
      if (this.isAwsUploader) {
        formData.append('content-type', file.type);
        Object.entries(this.fields).forEach(([key, value]) => formData.append(key, value));
      }
    },
    getPresignedUrl() {
      this.getUploadLink(this.useSecure).then(({ url, fields, fileKey }) => {
        this.preSignedUrl = url;
        this.fields = fields;
        this.fileKey = fileKey;
        if (this.$refs.vueDropzone) this.$refs.vueDropzone.setOption('url', url);
      });
    },
    getFileUploadUrl() {
      if (this.fileUploadUrl) {
        return `${getFileAPIBase()}${this.fileUploadUrl}`;
      }
      const { idIssuer: issuerId } = this.$route.params;
      return issuerId
        ? `${getFileAPIBase()}issuers/${issuerId}/configuration/general/upload-tmp-asset-to-server`
        : `${getFileAPIBase()}upload`;
    },
    clearDropzone() {
      this.$refs.vueDropzone.getAcceptedFiles().forEach((file) => {
        this.$refs.vueDropzone.removeFile(file);
      });
      this.$refs.vueDropzone.getRejectedFiles().forEach((file) => {
        this.$refs.vueDropzone.removeFile(file);
      });
      const placeholderUrl = this.defaultUrl || this.placeholder || this.getImagePath('upload.png', 'theme');
      if (placeholderUrl) {
        const mockFile = {};
        this.$refs.vueDropzone.dropzone.emit('addedfile', mockFile);
        this.$refs.vueDropzone.dropzone.emit('complete', mockFile);
        this.$refs.vueDropzone.dropzone.emit('thumbnail', mockFile, placeholderUrl.toString());
        this.$refs.vueDropzone.dropzone.on('addedfile', () => {
          this.$refs.vueDropzone.dropzone.emit('removedfile', mockFile);
        });
      }
    },
  },
};
</script>

<style src="~/../node_modules/vue2-dropzone/dist/vue2Dropzone.min.css"></style>
<style lang="scss">
  .cp-uploader.small-footprint.document-error {
    border: 1px solid red;
    border-radius: 5px;
  }
  .small-footprint.cp-uploader.dropzone {
    min-height: auto;
    padding: 10px;
  }
  .dropzone.show-message div.dz-message {
    display: block;
    font-size: 1.0rem;
  }
  .cp-uploader.dropzone {
    text-align: center;
    padding: 0;
    min-height: 256px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px dashed rgba(10, 23, 39, 0.06);

    .dz-details {
      display: none;
    }
    .dz-preview {
      pointer-events: none;
      background: none;
      width: auto;
      margin: auto;
      max-width: 70%;
      height: auto;
      min-height: 0;

      img {
        max-height: 180px;
        max-width: 100%;
      }
    }

    .dz-preview .dz-image img:not([src]) {
      width: 100px;
      height: 100px;
    }

    .dz-error-mark {
      svg {
        border-radius: 50%;
        background-color: rgba(255, 0, 0, 0.7);
        border: 1px rgba(255, 0, 0, 0.7) solid;
      }
    }

    .dz-success-mark {
      background: none;
      box-shadow: none;
    }

    .dz-message {
      padding: 0;
      font-weight: 500;
      font-size: 1.313rem;
      margin-right: 1em;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
</style>
