<template>
  <b-card no-body>
    <cp-card-head
      without-edit
      :card-name="$t('configurationJurisdictions.perCountryTable.title')"
      enable-create
      :create-label="$t('configurationJurisdictions.perCountryTable.addStateAndRegion')"
      @onCreateClick="openCreateStateAndRegionSettings"
    />

    <cp-table
      ref="cpTable"
      get-data-action="jurisdictions/getJurisdictionsCountries"
      :url-params="urlParams"
      :fields="fields"
      default-sort-direction="asc"
    >
      <template
        slot="countryName"
        slot-scope="{ rowData }"
      >
        <cp-country-name
          :country-code="rowData.item.countryCode"
          :locale="locale"
        />
      </template>
      <template
        slot="disclaimerType"
        slot-scope="{ rowData }"
      >
        {{ getDisclaimerType(rowData.item) }}
      </template>
      <template
        slot="qualificationType"
        slot-scope="{ rowData }"
      >
        {{ getQualificationType(rowData.item) }}
      </template>
      <template
        slot="qualificationRequired"
        slot-scope="{ rowData }"
      >
        <span
          class="ion"
          :class="{
            'ion-md-checkmark text-primary': rowData.item.qualificationRequired,
            'ion-md-close text-light': !rowData.item.qualificationRequired
          }"
        />
      </template>
      <template
        slot="reverseSolicitation"
        slot-scope="{ rowData }"
      >
        <span
          class="ion"
          :class="{
            'ion-md-checkmark text-primary': rowData.item.reverseSolicitation,
            'ion-md-close text-light': !rowData.item.reverseSolicitation
          }"
        />
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex justify-content-start">
          <b-btn
            v-b-tooltip.hover="true"
            class="mr-3"
            variant="default edit-operator-btn btn-xs md-btn-flat"
            :title="$t('common.button.edit')"
            @click="editElem(rowData.item)"
          >
            <i class="ion ion-md-create" />
            {{ $t('common.button.edit') }}
          </b-btn>
          <b-btn
            v-if="rowData.item.stateAndRegionCode"
            v-b-tooltip.hover="true"
            class="mr-3"
            variant="default delete-operator-btn btn-xs md-btn-flat"
            :title="$t('common.button.delete')"
            @click="deleteElem(rowData.item)"
          >
            <i class="ion ion-md-create" />
            {{ $t('common.button.delete') }}
          </b-btn>
        </div>
      </template>
    </cp-table>

    <cp-jurisdictions-edit-modal
      ref="CpJurisdictionsEditModal"
      :modal-data="modalData"
      @onSubmit="saveChanges"
    />
    <create-state-region-setting-modal
      ref="createStateAndRegionSettingModal"
      @onSuccess="stateAndRegionSettingCreated"
    />
    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="$t('configurationJurisdictions.confirmDeleteModal.title')"
      @onOk="deleteStateAndRegionSetting"
    >
      {{ $t('configurationJurisdictions.confirmDeleteModal.message') }}
    </cp-confirm-modal>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpCardHead from './card-head';
import CpCountryName from '~/components/common/country-name';
import CpTable from '~/components/shared/cp-table';
import { getCountryName } from '~/utilities/i18n-util';
import CpJurisdictionsEditModal from './jurisdictions-edit-modal';
import CreateStateRegionSettingModal from './create-state-region-setting-modal';
import fields from '../fields';
import { disclaimerTypeLabels, qualificationTypeLabels } from '../options';

export default {
  name: 'PerCountryTable',

  components: {
    CpConfirmModal,
    CpCountryName,
    CpTable,
    CpCardHead,
    CpJurisdictionsEditModal,
    CreateStateRegionSettingModal,
  },

  data() {
    return {
      fields,
      modalData: {
        qualification: {},
        disclaimer: {},
      },
      urlParams: { issuerId: this.$route.params.idIssuer },
      locale: null,
      dataForDelete: {},
    };
  },

  computed: {
    ...mapGetters('global', ['statesAndRegions']),
  },

  created() {
    this.$store.watch(({ global }) => {
      if (global && global.locale !== this.locale) {
        this.locale = global.locale;
      }
    });

    if (!this.statesAndRegions.length) this.fetchStatesAndRegions();
  },

  methods: {
    ...mapActions('jurisdictions', [
      'fetchCountryByCode',
      'updateIssuerCountry',
      'updateIssuerStateRegionSetting',
      'fetchIssuerStateRegionSetting',
      'deleteIssuerStateRegionSetting',
      'getJurisdictionsStatesAndRegions',
    ]),
    ...mapActions('global', ['fetchStatesAndRegions']),

    getStateRegionName(stateAndRegionId) {
      if (!stateAndRegionId) return null;

      return this.statesAndRegions.find(item => item.id === stateAndRegionId).name;
    },

    editElem({ countryCode, stateAndRegionCode, areaName, areaType }) {
      const issuerId = this.$route.params.idIssuer;

      const promise = stateAndRegionCode
        ? this.fetchIssuerStateRegionSetting({ issuerId, countryCode, stateAndRegionCode, areaType })
        : this.fetchCountryByCode({ issuerId, countryCode });

      promise.then((data) => {
        this.modalData = {
          ...data,
          areaType,
          areaName,
          stateAndRegionCode,
          countryCode,
          countryName: getCountryName(countryCode, this.locale),
        };
        this.$refs.CpJurisdictionsEditModal.show();
      });
    },

    deleteElem({ countryCode, stateAndRegionCode, areaType }) {
      if (!stateAndRegionCode) return;

      this.dataForDelete = { countryCode, stateAndRegionCode, areaType };
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },

    deleteStateAndRegionSetting() {
      const issuerId = this.$route.params.idIssuer;
      const { countryCode, stateAndRegionCode, areaType } = this.dataForDelete;

      this.deleteIssuerStateRegionSetting({ issuerId, countryCode, stateAndRegionCode, areaType }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.getJurisdictionsStatesAndRegions(issuerId);
        this.$refs.cpConfirmModal.$refs.confirmModal.hide();
      });
    },

    saveChanges(data) {
      const issuerId = this.$route.params.idIssuer;
      const { countryCode, areaType, stateAndRegionCode } = data;

      const promise = stateAndRegionCode
        ? this.updateIssuerStateRegionSetting({ issuerId, countryCode, stateAndRegionCode, data, areaType })
        : this.updateIssuerCountry({ issuerId, countryCode, data });

      promise.then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.CpJurisdictionsEditModal.$refs.jurisdictionsModal.$refs.generalModal.hide();
      });
    },

    openCreateStateAndRegionSettings() {
      this.$refs.createStateAndRegionSettingModal.show();
    },

    getDisclaimerType({ disclaimerType, disclaimerName }) {
      if (disclaimerType === 'specific') return disclaimerName;
      return this.$t(disclaimerTypeLabels[disclaimerType]);
    },

    getQualificationType({ qualificationType, qualificationName }) {
      if (qualificationType === 'specific') return qualificationName;
      return this.$t(qualificationTypeLabels[qualificationType]);
    },

    stateAndRegionSettingCreated() {
      this.$refs.cpTable.updateTableData();
      const issuerId = this.$route.params.idIssuer;
      this.getJurisdictionsStatesAndRegions(issuerId);
    },
  },
};
</script>

<style lang="scss" scoped>
  .card-header {
    border-bottom: none;
  }
</style>
