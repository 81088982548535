<template>
  <div class="card mb-5">
    <cp-card-head
      :card-name="$t('configurationJurisdictions.disclaimersAndDefinitions.title')"
      :edit-mode="editMode"
      :disable-edit="Boolean(editableCard)"
      @onSaveChanges="saveChanges"
      @onChangeModeProxy="checkEditBlock"
    />
    <div class="card-body">
      <h5>{{ $t('configurationJurisdictions.disclaimersAndDefinitions.subTitle.generalDisclaimer') }}</h5>
      <p>{{ $t('configurationJurisdictions.disclaimersAndDefinitions.label.generalDisclaimer') }}</p>
      <div class="card border-light mt-3">
        <div
          v-if="!editMode"
          class="card-body"
        >
          {{ disclaimersAndDefinitions.generalDisclaimer }}
        </div>
        <div v-else>
          <b-textarea
            v-model="model.generalDisclaimer"
            :placeholder="$t('configurationJurisdictions.disclaimersAndDefinitions.label.placeholder')"
            rows="8"
          />
        </div>
      </div>
    </div>
    <hr class="my-0 mx-0">
    <div class="card-body">
      <h5>{{ $t('configurationJurisdictions.disclaimersAndDefinitions.subTitle.standardCountryDisclaimer') }}</h5>
      <p>{{ $t('configurationJurisdictions.disclaimersAndDefinitions.label.standardCountryDisclaimer') }}</p>
      <div class="card border-light mt-3">
        <div
          v-if="!editMode"
          class="card-body"
        >
          {{ disclaimersAndDefinitions.standardCountryDisclaimer }}
        </div>
        <div v-else>
          <b-textarea
            v-model="model.standardCountryDisclaimer"
            :placeholder="$t('configurationJurisdictions.disclaimersAndDefinitions.label.placeholder')"
            rows="8"
          />
        </div>
      </div>
    </div>
    <hr class="my-0 mx-0">
    <div class="card-body">
      <h5>{{ $t('configurationJurisdictions.disclaimersAndDefinitions.subTitle.standardQualificationDefinition') }}</h5>
      <p>{{ $t('configurationJurisdictions.disclaimersAndDefinitions.label.standardQualificationDefinition') }}</p>
      <div class="card border-light mt-3">
        <div
          v-if="!editMode"
          class="card-body"
        >
          {{ disclaimersAndDefinitions.standardQualificationDefinition }}
        </div>
        <div v-else>
          <b-textarea
            v-model="model.standardQualificationDefinition"
            :placeholder="$t('configurationJurisdictions.disclaimersAndDefinitions.label.placeholder')"
            rows="8"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CpCardHead from './card-head';
import CpJurisdictionEditMode from '~/mixins/jurisdiction-edit-mode';

export default {
  name: 'Disclaimers',
  components: {
    CpCardHead,
  },
  mixins: [
    CpJurisdictionEditMode('disclaimers'),
  ],
  computed: mapState('jurisdictions', ['disclaimersAndDefinitions']),
  methods: {
    initData() {
      this.model = { ...this.disclaimersAndDefinitions };
    },
  },
};
</script>
