<template>
  <div>
    <cp-button
      size
      variant="outline-info mr-3"
      @click="openModal"
    >
      <i class="ion ion-md-arrow-up" />
      {{ $t('configurationJurisdictions.button.importJurisdictionsSettings') }}
    </cp-button>

    <cp-import-jurisdictions-modal
      ref="cpImportJurisdictions"
      @onImport="handleImport"
    />
    <cp-import-jurisdictions-errors-list-modal
      ref="cpImportJurisdictionsErrorsList"
      :table-data="errorList"
      :error-message="errorMessage"
      :is-invalid-csv="isInvalidCsv"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CpImportJurisdictionsModal from '~/pages/_idIssuer/configuration/jurisdictions/components/import-jurisdictions-modal';
import CpImportJurisdictionsErrorsListModal from '~/pages/_idIssuer/configuration/jurisdictions/components/import-jurisdictions-errors-list-modal';
import CpButton from '~/components/common/standalone-components/cp-button';

export default {
  name: 'CpImportJurisdictions',
  components: {
    CpImportJurisdictionsModal,
    CpImportJurisdictionsErrorsListModal,
    CpButton,
  },
  data() {
    return {
      errorList: [],
      errorName: '',
      errorMessage: '',
    };
  },
  computed: {
    isInvalidCsv() {
      return this.errorName === 'IMPORT_INVALID_CSV';
    },
  },
  methods: {
    ...mapActions('jurisdictions', ['importJurisdictionsSettings']),
    openModal() {
      this.$refs.cpImportJurisdictions.$refs.importJurisdictions.show();
    },
    handleImport({ fileKey }) {
      this.importJurisdictionsSettings({
        issuerId: this.$route.params.idIssuer,
        body: {
          fileKey,
        },
      }).then(() => {
        this.errorList = [];
        this.errorName = '';
        this.$forceUpdate();
        this.$emit('onJurisdictionsImported');
      }).catch(({ response }) => {
        const { data: errorData } = response;
        this.errorList = errorData.data || [];
        this.errorName = errorData.error;
        this.errorMessage = errorData.message;
        this.$refs.cpImportJurisdictionsErrorsList.$refs.cpImportJurisdictionsErrorsList.show();
      }).finally(() => {
        this.$refs.cpImportJurisdictions.$refs.importJurisdictions.hide();
      });
    },
  },
};
</script>
