export const disclaimerTypeLabels = {
  standard: 'configurationJurisdictions.perCountryTable.status.disclaimerTypes.standard',
  specific: 'configurationJurisdictions.perCountryTable.status.disclaimerTypes.specific',
  none: 'configurationJurisdictions.perCountryTable.status.disclaimerTypes.none',
};

export const qualificationTypeLabels = {
  standard: 'configurationJurisdictions.perCountryTable.status.qualTypes.standard',
  specific: 'configurationJurisdictions.perCountryTable.status.qualTypes.specific',
};
