<template>
  <div class="dual-list-box-wrapper">
    <div class="list-box-item sourceList">
      <label class="dual-list-box-label">{{ labels.left || $t('component.cpDualListBox.label.instances') }}</label>
      <span class="dual-list-info">
        {{ sourceListCount }}
      </span>
      <div class="actions">
        <div
          class="btn-action left-col-double-arrow-forward"
          @click="moveAllDestination"
        >
          <i class="ion ion-ios-arrow-forward" />
          <i class="ion ion-ios-arrow-forward" />
        </div>
        <div
          class="btn-action left-col-arrow-forward"
          @click="moveDestination"
        >
          <i class="ion ion-ios-arrow-forward" />
        </div>
      </div>
      <div class="list-block">
        <div
          v-if="showSearch"
          class="search-box"
        >
          <cp-input
            v-model="searchSource"
            :placeholder="$t('component.cpDualListBox.input.search.placeholder')"
            input-class="search-input"
          />
          <div
            v-if="searchSource"
            class="clear-search"
            :title="$t('component.cpDualListBox.label.clearSearch')"
            @click="searchSource=''"
          >
            <i class="ion ion-ios-close-circle-outline" />
          </div>
        </div>
        <ul class="list-box">
          <li
            v-for="(item, index) in sourceList"
            :key="index"
            class="list-item"
            :class="{ selectedBg: item.selected }"
            @click="selectSource(item.value)"
          >
            {{ item.text }}
          </li>
          <li
            v-if="!sourceList.length && initSource.length"
            class="list-item"
          >
            {{ $t('component.cpDualListBox.label.noResultsFound') }}
          </li>
        </ul>
      </div>
    </div>
    <div class="list-box-item">
      <label>{{ labels.right || $t('component.cpDualListBox.label.selectedInstances') }}</label>
      <span class="dual-list-info">
        {{ destinationListCount }}
      </span>
      <div class="actions">
        <div
          class="btn-action right-col-arrow-forward"
          @click="moveSource"
        >
          <i class="ion ion-ios-arrow-back" />
        </div>
        <div
          class="btn-action right-col-double-arrow-forward"
          @click="moveAllSource"
        >
          <i class="ion ion-ios-arrow-back" />
          <i class="ion ion-ios-arrow-back" />
        </div>
      </div>
      <div class="list-block">
        <div
          v-if="showSearch"
          class="search-box"
        >
          <cp-input
            v-model="searchDestination"
            :placeholder="$t('component.cpDualListBox.input.search.placeholder')"
            input-class="search-input"
          />
          <div
            v-if="searchDestination"
            class="clear-search"
            :title="$t('component.cpDualListBox.label.clearSearch')"
            @click="searchDestination=''"
          >
            <i class="ion ion-ios-close-circle-outline" />
          </div>
        </div>
        <ul class="list-box">
          <li
            v-for="(item, index) in destinationList"
            :key="index"
            class="list-item"
            :class="{ selectedBg: item.selected }"
            @click="selectDestination(item.value)"
          >
            {{ item.text }}
          </li>
          <li
            v-if="!destinationList.length && initDestination.length"
            class="list-item"
          >
            {{ $t('component.cpDualListBox.label.noResultsFound') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CpInput from '~/components/common/standalone-components/inputs/cp-input';

export default {
  components: {
    CpInput,
  },
  props: {
    source: { // structure [{ text, value }]
      type: Array,
      required: true,
    },
    destination: { // structure [{ text, value }]
      type: Array,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Object,
      default: () => ({
        left: '',
        right: '',
      }),
    },
  },
  data() {
    return {
      searchSource: '',
      searchDestination: '',
      initSource: [],
      initDestination: [],
    };
  },
  computed: {
    sourceListCount() {
      const list = this.sourceList.length;
      return list
        ? this.$t('component.cpDualListBox.label.showingAll', [list])
        : this.$t('component.cpDualListBox.label.emptyList');
    },
    destinationListCount() {
      const list = this.destinationList.length;
      return list
        ? this.$t('component.cpDualListBox.label.showingAll', [list])
        : this.$t('component.cpDualListBox.label.emptyList');
    },
    sourceList() {
      return this.initSource.filter(item => (
        item.text.toLowerCase().includes(this.searchSource.toLowerCase())
      ));
    },
    destinationList() {
      return this.initDestination.filter(item => (
        item.text.toLowerCase().includes(this.searchDestination.toLowerCase())
      ));
    },
  },
  watch: {
    source: {
      handler() {
        this.updateLists();
      },
    },
  },
  created() {
    this.updateLists();
  },
  methods: {
    updateLists(source, destination) {
      this.initSource = source || this.source;
      this.initDestination = destination || this.destination;
      if (!source || !destination) {
        return;
      }

      this.$emit('onChangeList', {
        source,
        destination,
      });
    },
    clearSearches() {
      this.searchSource = '';
      this.searchDestination = '';
    },
    moveDestination() {
      let selected = this.initSource.filter(item => item.selected);
      if (!selected.length) return;
      selected = selected.map(item => ({
        ...item,
        selected: false,
      }));
      const destination = [...selected, ...this.initDestination];
      const source = this.initSource.filter(item => !item.selected);
      this.clearSearches();
      this.updateLists(source, destination);
    },
    moveSource() {
      let selected = this.initDestination.filter(item => item.selected);
      if (!selected.length) return;
      selected = selected.map(item => ({
        ...item,
        selected: false,
      }));
      const source = [...selected, ...this.initSource];
      const destination = this.initDestination.filter(item => !item.selected);
      this.clearSearches();
      this.updateLists(source, destination);
    },
    moveAllDestination() {
      const destination = [
        ...this.initSource.map(item => ({ ...item, selected: false })),
        ...this.initDestination,
      ];

      this.clearSearches();
      this.updateLists([], destination);
    },
    moveAllSource() {
      const source = [
        ...this.initDestination.map(item => ({ ...item, selected: false })),
        ...this.initSource,
      ];

      this.clearSearches();
      this.updateLists(source, []);
    },
    selectDestination(clickedLabel) {
      const destination = this.initDestination.map((item) => {
        if (item.value === clickedLabel) {
          item.selected = !item.selected;
        }
        return item;
      });

      this.updateLists(this.initSource, destination);
    },
    selectSource(clickedLabel) {
      const source = this.initSource.map((item) => {
        if (item.value === clickedLabel) {
          item.selected = !item.selected;
        }
        return item;
      });

      this.updateLists(source, this.initDestination);
    },
  },
};
</script>

<style lang="scss">
  .dual-list-box-wrapper {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    .list-box-item {
      width: 40%;
      label {
        margin-bottom: 0.2rem;
      }
      .dual-list-info {
        margin-top: 0.5rem;
        display: block;
        font-size: 11px;
      }
      .list-block {
        border: 1px solid rgba(10, 23, 39, 0.1);
        border-radius: 3px;
        .search-box {
          position: relative;
          width: 100%;
          .search-input {
            border: none;
            border-bottom: 1px solid rgba(10, 23, 39, 0.1);
            border-radius: 0;
            padding: 0.5rem 1rem;
          }
          .clear-search {
            position: absolute;
            padding: 0.5rem;
            right: 0;
            top: 0;
            cursor: pointer;
            font-weight: bold;
            color: #e74c3c;
          }
        }
        .list-box {
          height: 164px;
          overflow: auto;
          list-style: none;
          padding: 0;
          margin: 0;
          .selectedBg {
            background-color: #f5f5f5;
          }
          .list-item {
            padding: 0.2rem 1rem;
            cursor: pointer;
          }
        }
      }
    }
    .sourceList{
      margin-right: 10%;
    }
    .actions {
      display: flex;
      margin: 0.5rem auto;
      .btn-action {
        width: 100%;
        color: #627d8e;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        border: 1px solid rgba(10, 23, 39, 0.1);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        cursor: pointer;
      }
      .btn-action:hover {
        background-color: #f5f5f5;
        transition: 0.5s;
      }
      .left-col-double-arrow-forward {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        width: 40%;
      }
      .left-col-arrow-forward {
        width: 60%;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
      .right-col-arrow-forward {
        width: 60%;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
      .right-col-double-arrow-forward {
        width: 40%;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }
</style>
