const tdClass = 'align-middle';

export default [
  {
    key: 'countryName',
    i18nKey: 'configurationJurisdictions.perCountryTable.label.table.country',
    sortable: true,
    tdClass,
  },
  {
    key: 'stateAndRegionName',
    i18nKey: 'configurationJurisdictions.perCountryTable.label.table.regionState',
    sortable: true,
    tdClass,
  },
  {
    key: 'disclaimerType',
    i18nKey: 'configurationJurisdictions.perCountryTable.label.table.disclaimerType',
    tdClass,
  },
  {
    key: 'qualificationType',
    i18nKey: 'configurationJurisdictions.perCountryTable.label.table.qualificationType',
    tdClass,
  },
  {
    key: 'qualificationRequired',
    i18nKey: 'configurationJurisdictions.perCountryTable.label.table.qualificationRequired',
    sortable: true,
    tdClass,
  },
  {
    key: 'reverseSolicitation',
    i18nKey: 'configurationJurisdictions.perCountryTable.label.table.reverseSolicitation',
    sortable: true,
    tdClass,
  },
  {
    key: 'actions',
    label: ' ',
    tdClass: `text-nowrap ${tdClass} text-center`,
  },
];
