<template>
  <cp-general-modal
    ref="createStateAndRegionSettingModal"
    :title="$t('configurationJurisdictions.addStateRegionModal.title')"
    @onOk="handleCreateItem"
  >
    <div class="row">
      <div class="col-md-4">
        <label class="form-label">
          {{ $t('configurationJurisdictions.addStateRegionModal.country') }}
        </label>
      </div>
      <div class="col-md-8">
        <cp-select
          v-model="countryCode"
          name="countryCode"
          placeholder
          validate="required"
          :options="countryList"
          @input="selectCountry($event)"
        />
      </div>
    </div>
    <div
      v-show="statesList.length"
      class="row"
    >
      <div class="col-md-4">
        <b-form-radio
          v-model="areaType"
          plain
          :value="areaTypes.state"
        >
          {{ $t('configurationJurisdictions.addStateRegionModal.state') }}
        </b-form-radio>
      </div>
      <div class="col-md-8">
        <cp-select
          ref="stateCode"
          v-model="stateCode"
          name="stateCode"
          placeholder
          :validate="areaType === areaTypes.state ? 'required' : ''"
          :options="statesAndRegionsList(areaTypes.state)"
          @input="selectState()"
        />
      </div>
    </div>
    <div
      v-show="regionsList.length"
      class="row"
    >
      <div class="col-md-4">
        <b-form-radio
          v-model="areaType"
          plain
          :value="areaTypes.region"
        >
          {{ $t('configurationJurisdictions.addStateRegionModal.region') }}
        </b-form-radio>
      </div>
      <div class="col-md-8">
        <cp-select
          ref="regionCode"
          v-model="regionCode"
          name="regionCode"
          placeholder
          :validate="areaType === areaTypes.region ? 'required' : ''"
          :options="statesAndRegionsList(areaTypes.region)"
          @input="selectRegion()"
        />
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

const areaTypes = {
  state: 'state',
  region: 'region',
};

export default {
  name: 'CreateStateRegionSettingModal',
  components: {
    CpGeneralModal,
    CpSelect,
  },
  data() {
    return {
      countryCode: null,
      stateCode: null,
      regionCode: null,
      areaTypes,
      areaType: areaTypes.state,
      statesList: [],
      regionsList: [],
    };
  },
  computed: {
    ...mapGetters('global', ['statesAndRegions', 'countries']),
    countryList() {
      if (!this.statesAndRegions.length || !this.countries.length) return [];


      const countriesWithStatesAndRegionSet = this.statesAndRegions.reduce((result, { countryCode }) => {
        result.add(countryCode);

        return result;
      }, new Set());

      return this
        .countries
        .filter(({ countryCode }) => countriesWithStatesAndRegionSet.has(countryCode))
        .map(({ name, countryCode }) => ({ value: countryCode, text: name }));
    },
  },
  methods: {
    ...mapActions('jurisdictions', ['createIssuerStateRegionSetting']),
    selectState() {
      this.regionCode = null;
      this.areaType = this.areaTypes.state;
    },

    selectRegion() {
      this.stateCode = null;
      this.areaType = this.areaTypes.region;
    },

    statesAndRegionsList(areaType) {
      if (!this.countryCode || !this.statesAndRegions.length) return [];

      return this.getList(areaType, this.countryCode);
    },
    getList(areaType, currentCountryCode) {
      return this
        .statesAndRegions
        .filter(({ countryCode, type }) => countryCode === currentCountryCode && type === areaType)
        .map(({ code, name }) => ({ value: code, text: name }));
    },
    show() {
      this.regionCode = null;
      this.stateCode = null;
      this.countryCode = null;
      this.statesList = [];
      this.regionsList = [];
      this.$refs.createStateAndRegionSettingModal.show();
    },
    hide() {
      this.$refs.createStateAndRegionSettingModal.hide();
    },
    selectCountry(countryCode) {
      this.statesList = this.getList(this.areaTypes.state, countryCode);
      this.regionsList = this.getList(this.areaTypes.region, countryCode);

      this.areaType = this.statesList.length ? this.areaTypes.state : this.areaTypes.region;

      this.regionCode = null;
      this.stateCode = null;
    },
    resetValues() {

    },
    handleCreateItem() {
      const { idIssuer: issuerId } = this.$route.params;
      const { countryCode, areaType } = this;
      const stateAndRegionCode = this.stateCode || this.regionCode;

      this.createIssuerStateRegionSetting({ issuerId, countryCode, stateAndRegionCode, areaType }).then(() => {
        this.$emit('onSuccess');
        this.hide();
      }).catch((e) => {
        if (!e.response || !e.response.data) return;

        const { data, error } = e.response.data;
        if (data === 'StateAndRegionSetting' && error === 'RESOURCE_EXISTS_ERROR') {
          this.regionCode = null;
          this.stateCode = null;
          this.$refs.stateCode.controlledValue = null;
          this.$refs.regionCode.controlledValue = null;
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
