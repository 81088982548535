<template>
  <b-modal
    ref="importJurisdictions"
    :ok-title="$t('configurationJurisdictions.importJurisdictionsModal.button.ok')"
    :cancel-title="$t('configurationJurisdictions.importJurisdictionsModal.button.cancel')"
    ok-variant="primary"
    cancel-variant="default"
    :ok-disabled="uploadDisabled"
    @ok="handleSubmit"
    @hide="clearData"
    @close="clearData"
    @cancel="clearData"
  >
    <div slot="modal-title">
      {{ $t('configurationJurisdictions.importJurisdictionsModal.title') }}
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="form-label mb-3">
            {{ $t('configurationJurisdictions.importJurisdictionsModal.label.update') }}
          </label>
          <cp-file-uploader
            id="import-jurisdictions-uploader"
            extension=".csv"
            @onChange="fileUploaded"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';

export default {
  name: 'CpImportJurisdictionsModal',
  components: {
    CpFileUploader,
  },
  data() {
    return {
      fileData: {},
    };
  },
  computed: {
    uploadDisabled() {
      return !Object.keys(this.fileData).length || !this.fileData.file.accepted;
    },
  },
  methods: {
    fileUploaded(fileData) {
      this.fileData = fileData;
    },
    handleSubmit(env) {
      env.preventDefault();
      this.$emit('onImport', this.fileData);
    },
    clearData() {
      this.fileData = {};
    },
  },
};
</script>
