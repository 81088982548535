<template>
  <div class="col-6 d-flex justify-content-end">
    <slot name="leftToButtons" />
    <b-tooltip
      v-if="showTooltip"
      :target="getTargetId"
      triggers="hover"
    >
      {{ tooltipMessage }}
    </b-tooltip>
    <span :id="getTargetId">
      <b-button
        v-if="!editMode"
        variant="primary"
        size="sm"
        :disabled="disableEdit"
        @click="$emit('changeMode')"
      >
        <i class="ion ion-md-create" />
        {{ $t('common.button.edit') }}
      </b-button>
    </span>
    <b-button
      v-if="editMode"
      variant="primary"
      size="sm"
      class="mr-3"
      @click="$emit('changeMode')"
    >
      {{ $t('common.button.cancel') }}
    </b-button>
    <b-button
      v-if="editMode"
      variant="primary"
      size="sm"
      @click="$emit('update')"
    >
      <i class="ion ion-md-checkmark" />
      {{ $t('common.button.saveChanges') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'CpEditButtons',
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    getTargetId() {
      return `edit-investor-details-${this._uid}`;
    },
  },
};
</script>
