<template>
  <cp-general-modal
    ref="jurisdictionsModal"
    title-icon="ion ion-ios-person-add"
    :title="$t('configurationJurisdictions.jurisdictionsEditModal.title', [modalData.countryName, getStateRegionName(country.stateAndRegionName)])"
    size="lg"
    scrollable
    :form-group-value="modalData"
    @onOk="handleSubmit"
  >
    <h5>
      {{ $t('configurationJurisdictions.jurisdictionsEditModal.label.disclaimer') }}
    </h5>
    <div class="row form-group">
      <div
        v-for="type in disclaimerTypes"
        :key="type.value"
        class="col"
      >
        <b-btn
          class="btn-primary btn-block btn-sm"
          :class="{'btn-outline-primary': country.disclaimer.type !== type.value}"
          @click="setType('disclaimer', type.value)"
        >
          <span
            v-if="country.disclaimer.type === type.value"
            class="ion ion-md-checkmark"
          />
          {{ type.name }}
        </b-btn>
      </div>
    </div>
    <cp-text-area
      v-if="country.disclaimer.type !== 'specific'"
      v-model="country.disclaimer.content"
      name="disclaimer.content"
      label=""
      :rows="5"
      :disabled="country.disclaimer.type!=='specific'"
    />
    <cp-autocomplete
      v-else
      v-model="countryDisclaimer"
      :label="$t('configurationJurisdictions.jurisdictionsEditModal.input.initialAgreement')"
      :placeholder="$t('configurationJurisdictions.jurisdictionsEditModal.label.placeholder')"
      :list="agreements"
      name="disclaimer.issuerAgreementId"
      display-attribute="name"
      value-attribute="id"
      validate="required"
    />
    <h5>
      {{ $t('configurationJurisdictions.jurisdictionsEditModal.label.qualification') }}
    </h5>
    <div class="card border-light mt-3">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-8">
            {{ $t('configurationJurisdictions.jurisdictionsEditModal.input.qualification') }}
          </div>
          <div class="col-4 d-flex justify-content-end">
            <cp-switch
              v-model="country.qualificationRequired"
              name="qualificationRequired"
            />
          </div>
        </div>
      </div>
      <div class="card-footer bg-light">
        {{ $t('configurationJurisdictions.jurisdictionsEditModal.message.qualification') }}
      </div>
    </div>
    <div class="row mt-5">
      <div
        v-for="type in qualificationTypes"
        :key="type.value"
        class="col"
      >
        <b-btn
          class="btn-primary btn-block btn-sm"
          :class="{'btn-outline-primary': country.qualification.type !== type.value}"
          @click="setType('qualification', type.value)"
        >
          <span
            v-if="country.qualification.type === type.value"
            class="ion ion-md-checkmark"
          />
          {{ type.name }}
        </b-btn>
      </div>
    </div>
    <cp-text-area
      v-if="country.qualification.type !== 'specific'"
      v-model="country.qualification.content"
      class="mt-3"
      name="qualification.content"
      label=""
      :rows="5"
      :disabled="country.qualification.type !== 'specific'"
    />
    <cp-autocomplete
      v-else
      v-model="countryQualification"
      class="mt-3"
      :label="$t('configurationJurisdictions.jurisdictionsEditModal.input.accreditedAgreements')"
      :placeholder="$t('configurationJurisdictions.jurisdictionsEditModal.label.placeholder')"
      :list="agreements"
      name="qualification.issuerAgreementId"
      display-attribute="name"
      value-attribute="id"
      validate="required"
    />
    <b-card class="border-light mt-3">
      <h6>
        {{ $t('configurationJurisdictions.jurisdictionsEditModal.input.qualificationTitle') }}
      </h6>
      <cp-checkbox-group
        :value="country.accreditationText"
        :options="qualificationTitleOptions"
        @input="setQualificationTitle"
      />
      <cp-input
        v-model="otherQualificationValue"
        label=""
        :placeholder="$t('configurationJurisdictions.jurisdictionsEditModal.label.placeholder')"
        :disabled="isStandardQualificationTitle"
      />
    </b-card>
    <div class="row form-group justify-content-sm-between">
      <div
        v-for="toggle in toggles"
        :key="toggle"
        class="col-5 d-flex align-items-center justify-content-sm-between mt-3"
      >
        <span>
          {{ $t(`configurationJurisdictions.jurisdictionsEditModal.label.${toggle}`) }}
        </span>
        <cp-switch
          v-model="country[toggle]"
          :name="toggle"
        />
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import {
  CpTextArea,
  CpCheckboxGroup,
  CpInput,
  CpSwitch,
} from '~/components/common/standalone-components/inputs';
import CpAutocomplete from '~/components/common/standalone-components/cp-auto-complete';

export default {
  name: 'JurisdictionsEditModal',

  components: {
    CpGeneralModal,
    CpTextArea,
    CpCheckboxGroup,
    CpInput,
    CpSwitch,
    CpAutocomplete,
  },

  props: {
    modalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      country: {},
      otherQualificationValue: '',
      toggles: ['additionalAccreditation', 'accreditationFirst', 'reverseSolicitation'],
    };
  },
  computed: {
    ...mapGetters('issuerAgreements', ['issuerAgreementList']),
    ...mapGetters('global', ['statesAndRegions']),
    agreements() {
      return this.issuerAgreementList.map(({ name, id }) => ({ name, id }));
    },
    countryDisclaimer: {
      get() {
        const agreement = this.agreements.find(({ id }) => id === this.country.disclaimer.issuerAgreementId);
        return agreement && agreement.name;
      },
      set(value) {
        const agreement = this.agreements.find(({ name }) => name === value);
        this.country.disclaimer.issuerAgreementId = agreement && agreement.id;
      },
    },
    countryQualification: {
      get() {
        const agreement = this.agreements.find(({ id }) => id === this.country.qualification.issuerAgreementId);
        return agreement && agreement.name;
      },
      set(value) {
        const agreement = this.agreements.find(({ name }) => name === value);
        this.country.qualification.issuerAgreementId = agreement && agreement.id;
      },
    },
    isStandardQualificationTitle() {
      return !(this.country.accreditationText && this.isOther(this.country.accreditationText));
    },
    qualificationTypes() {
      return [
        {
          name: this.$t('configurationJurisdictions.jurisdictionsEditModal.option.qualification.standard'),
          value: 'standard',
        },
        {
          name: this.$t('configurationJurisdictions.jurisdictionsEditModal.option.qualification.specific'),
          value: 'specific',
        },
      ];
    },
    disclaimerTypes() {
      return [{
        name: this.$t('configurationJurisdictions.jurisdictionsEditModal.option.disclaimer.standard'),
        value: 'standard',
      }, {
        name: this.$t('configurationJurisdictions.jurisdictionsEditModal.option.disclaimer.specific'),
        value: 'specific',
      }, {
        name: this.$t('configurationJurisdictions.jurisdictionsEditModal.option.disclaimer.none'),
        value: 'none',
      }];
    },
    qualificationTitleOptions() {
      return [{
        text: this.$t('configurationJurisdictions.jurisdictionsEditModal.option.qualificationTitle.accredited'),
        value: 'accredited',
      }, {
        text: this.$t('configurationJurisdictions.jurisdictionsEditModal.option.qualificationTitle.qualification'),
        value: 'qualification',
      }, {
        text: this.$t('configurationJurisdictions.jurisdictionsEditModal.option.qualificationTitle.professional'),
        value: 'professional',
      }, {
        text: this.$t('configurationJurisdictions.jurisdictionsEditModal.option.qualificationTitle.other'),
        value: 'other',
      }];
    },
  },
  watch: {
    modalData: {
      handler(val) {
          this.otherQualificationValue = '';
          this.country = JSON.parse(JSON.stringify(val));
          if (this.country && this.isOther(this.country.accreditationText)) {
            const { accreditationText } = { ...this.country };
            this.otherQualificationValue = accreditationText;
            this.country.accreditationText = 'other';
          }
      },
      immediate: true,
    },
  },
  created() {
    this.getIssuerAgreements({ params: { issuerId: this.$route.params.idIssuer, limit: 999 } });
  },
  methods: {
    ...mapActions('issuerAgreements', ['getIssuerAgreements']),

    getStateRegionName(stateAndRegionName) {
      return stateAndRegionName ? `/${stateAndRegionName}` : '';
    },
    isOther(qualificationValue) {
      return !['accredited', 'qualification', 'professional'].includes(qualificationValue.toLowerCase());
    },
    setType(field, value) {
      this.country[field].type = value;
    },

    setQualificationTitle(val) {
      this.country.accreditationText = val || 'other';
    },

    handleSubmit() {
      if (this.otherQualificationValue && this.isOther(this.country.accreditationText)) this.country.accreditationText = this.otherQualificationValue;
      this.$emit('onSubmit', this.country);
    },
    show() {
      this.$refs.jurisdictionsModal.show();
    },
  },
};
</script>
