<template>
  <b-modal
    ref="cpImportJurisdictionsErrorsList"
    ok-only
    size="lg"
    ok-variant="primary"
  >
    <div slot="modal-title">
      <i class="ion ion-ios-person-add" />
      {{ $t('configurationJurisdictions.importJurisdictionsErrorsModal.title') }}
    </div>
    <div v-if="emptyList">
      {{ errorMessage || $t('configurationJurisdictions.importJurisdictionsErrorsModal.message.invalid') }}
    </div>
    <div
      v-else
      class="cp-errors-table"
    >
      <div class="d-flex justify-content-end pb-4">
        <cp-button
          size="sm"
          variant="outline-primary ml-3 ion ion-ios-download"
          @click="exportErrorList"
        >
          {{ $t('configurationJurisdictions.importJurisdictionsErrorsModal.button.download') }}
        </cp-button>
      </div>
      <b-table
        hover
        striped
        bordered
        :items="getData"
        :fields="getFields"
        class="card-table"
      />
    </div>
  </b-modal>
</template>

<script>
import FileSaver from 'file-saver';
import moment from 'moment';
import { mapGetters } from 'vuex';
import CpButton from '~/components/common/standalone-components/cp-button';

export default {
    name: 'CpImportJurisdictionsErrorsListModal',
    components: {
        CpButton,
    },
    props: {
        isInvalidCsv: {
            type: Boolean,
            default: false,
        },
        tableData: {
            type: Array,
            required: true,
        },
        errorMessage: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters('issuerList', ['currentIssuer']),
        emptyList() {
            return !this.tableData.length;
        },
        getFields() {
            const fields = [
                { key: 'fieldName', label: this.$t('configurationJurisdictions.importJurisdictionsErrorsModal.label.fieldName'), tdClass: 'align-middle' },
                { key: 'type', label: this.$t('configurationJurisdictions.importJurisdictionsErrorsModal.label.errorType'), tdClass: 'align-middle' },
                { key: 'message', label: this.$t('configurationJurisdictions.importJurisdictionsErrorsModal.label.message'), tdClass: 'align-middle' },
            ];

            if (!this.isInvalidCsv) {
                fields.unshift({ key: 'index', tdClass: 'align-middle' });
            }
            return fields;
        },
        getData() {
            if (this.isInvalidCsv) {
                return this.tableData;
            }
            let collection = [];
            this.tableData.forEach(({ index, errors }) => {
                collection = [
                    ...collection,
                    ...errors.map(item => ({ index, ...item })),
                ];
            });
            return collection;
        },
    },
    methods: {
        exportErrorList() {
            const filename = `${this.currentIssuer.name.replace(/\W/g, '_')}_${moment().format('YYYY-MM-DD_h-mm')}_errors_list`;
            let csvErrorList = this.$t('configurationJurisdictions.importJurisdictionsErrorsModal.label.csvErrorList');
            this.getData.forEach(({ fieldName, type, message }) => {
                csvErrorList = `${csvErrorList}${fieldName},${type},${message}\n`;
            });
            const blob = new Blob([csvErrorList]);
            FileSaver.saveAs(blob, `${filename}.csv`);
        },
    },
};
</script>

<style lang="scss">
    .cp-errors-table {
        max-height: 300px;
        overflow: auto;
    }
</style>
